import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { ChartFilterSearchForm } from './components/ChartFilterSearchForm';
import { StoreMap } from './components/StoreMap';
import { StyledContentWrapper } from 'styles/overrides';

export default function MappingDashboard() {
  const [search, setSearch] = useCustomSearchParams();

  return (
    <>
      <ChartFilterSearchForm
        onSubmit={(values) => setSearch(values)}
        initialValues={search}
      />
      <StyledContentWrapper style={{ paddingLeft: 0, paddingRight: 0 }}>
        <StoreMap search={search} />
      </StyledContentWrapper>
    </>
  );
}
