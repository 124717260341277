import { Pie } from '@ant-design/plots';
import { useGetDashboardStore } from 'api/reportApi';
import { DONE_COLOR, NOT_DONE_COLOR } from 'constants/colors';

export const StoreChart = ({ search }) => {
  const { data = [], isLoading } = useGetDashboardStore({
    params: search,
    options: {
      select(response) {
        if (response) {
          return [
            {
              type: 'Đã thực hiện',
              value: response.done,
            },
            {
              type: 'Chưa thực hiện',
              value: response.not_done,
            },
          ];
        }

        return [];
      },
    },
  });

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.75,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    color: [DONE_COLOR, NOT_DONE_COLOR],
    legend: {
      layout: 'horizontal',
      position: 'bottom',
    },
  };

  if (isLoading) {
    return null;
  }

  return <Pie {...config} />;
};
