import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import flatten from 'flat';
import moment from 'moment';
import { Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useSelector } from 'react-redux';

import THEME from 'config/themes';
import AppLocale from 'config/translations';
import { validateMessages } from 'constants/common';
import { PATH_NAME } from 'constants/routes';
import GlobalStyle from 'styles/globalStyle';
import Dashboard from './views/dashboard/Dashboard';
import { NotFound } from './components/layout/NotFound';
import ViewDashboard from 'views/dashboard/ViewDashboard';
import TrackingProgressDashboard from './views/dashboard/TrackingProgressDashboard';
import MappingDashboard from './views/dashboard/MappingDashboard';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  const locale = useSelector((state) => state.app.currentLocale);

  moment.locale(locale);

  return (
    <ConfigProvider
      locale={AppLocale[locale].antd}
      form={{ validateMessages: validateMessages[locale], requiredMark: false }}
      // componentSize="small"
    >
      <IntlProvider
        locale={locale}
        messages={flatten(AppLocale[locale].messages)}
      >
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={THEME}>
            <GlobalStyle />

            <Routes>
              <Route path={PATH_NAME.ROOT}>
                <Route index element={<Navigate to={PATH_NAME.DASHBOARD} />} />

                <Route path={PATH_NAME.DASHBOARD}>
                  <Route index element={<Dashboard />} />
                  <Route
                    path={PATH_NAME.TRACKING_PROGRESS_DASHBOARD}
                    element={<TrackingProgressDashboard />}
                  />
                  <Route
                    path={PATH_NAME.MAPPING_DASHBOARD}
                    element={<MappingDashboard />}
                  />
                </Route>

                <Route path="*" element={<NotFound />} />
              </Route>

              <Route path="*" element={<NotFound />} />
            </Routes>
          </ThemeProvider>

          <ReactQueryDevtools />
        </QueryClientProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}

export default App;
