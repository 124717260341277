import _ from 'lodash';
import { Table } from 'components/table';
import { useTrackingProgressTableData } from 'api/reportApi';

export const TrackingProgressTable = () => {
  const { data = [], isLoading } = useTrackingProgressTableData({
    options: {
      select(response) {
        let arr = [];
        let grandTotal = {
          store_total: 0,
          store_done: 0,
          shift_total: 0,
          shift_done: 0,
          key: 'Grand Total',
          id: 'grand_total',
        };

        _.forEach(response, (value, key) => {
          const items = _.map(value.detail, (item, index) => {
            return {
              ...item,
              length: index === 0 ? value.detail.length : 0,
              key,
              id: `${key}_${index}`,
            };
          });

          grandTotal.store_total += value.total.store_total;
          grandTotal.store_done += value.total.store_done;
          grandTotal.shift_total += value.total.shift_total;
          grandTotal.shift_done += value.total.shift_done;

          arr = [
            ...arr,
            ...items,
            { ...value.total, key: `${key} Total`, id: `${key}_total` },
          ];
        });

        return [...arr, grandTotal];
      },
    },
  });

  const columns = [
    {
      title: 'Khu vực',
      dataIndex: 'key',
      onCell({ length }) {
        if (length > 0) {
          return {
            rowSpan: length,
          };
        } else if (length === 0) {
          return { rowSpan: 0 };
        }

        return {};
      },
      onHeaderCell() {
        return {
          className: 'regionClass',
        };
      },
    },
    {
      title: 'Tỉnh',
      dataIndex: 'name',
      onHeaderCell() {
        return {
          className: 'regionClass',
        };
      },
    },
    {
      title: 'Tổng số CH',
      dataIndex: 'store_total',
      onHeaderCell() {
        return {
          className: 'storeClass',
        };
      },
    },
    {
      title: 'Tổng số CH đã thực hiện',
      dataIndex: 'store_done',
      onHeaderCell() {
        return {
          className: 'storeClass',
        };
      },
    },
    {
      title: '% đã thực hiện / tổng',
      render: ({ store_done, store_total }) => {
        const num = (store_done / store_total) * 100;
        const value = num ? num.toFixed(1) : 0;

        return `${value}%`;
      },
      onHeaderCell() {
        return {
          className: 'storeClass',
        };
      },
    },
    {
      title: 'Tổng số Shift',
      dataIndex: 'shift_total',
      onHeaderCell() {
        return {
          className: 'shiftClass',
        };
      },
    },
    {
      title: 'Số Shift đã thực hiện',
      dataIndex: 'shift_done',
      onHeaderCell() {
        return {
          className: 'shiftClass',
        };
      },
    },
    {
      title: '% đã thực hiện / tổng',
      render: ({ shift_done, shift_total }) => {
        const num = (shift_done / shift_total) * 100;
        const value = num ? num.toFixed(1) : 0;

        return `${value}%`;
      },
      onHeaderCell() {
        return {
          className: 'shiftClass',
        };
      },
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={data}
      columns={columns}
      pagination={false}
      loading={isLoading}
      rowClassName={(record) => {
        if (record.id.includes('_total')) {
          return 'totalClass';
        }
      }}
      size="small"
    />
  );
};
