export const PATH_NAME = {
  ROOT: '/',
  DASHBOARD: 'dashboard',
  TRACKING_PROGRESS_DASHBOARD: 'tracking-progress',
  MAPPING_DASHBOARD: 'mapping',
};

export const PATH_NAME_ACTION = {
  EDIT: 'edit',
};
