import { useState } from 'react';
import { Select, Form } from 'antd';
import _ from 'lodash';
import { FilterForm } from 'components/form';
import { StyledSpace, StyledFormItem, StyledSelect } from 'styles/overrides';
import { normalizeSearchData, normalizeSubmitSearchData } from 'utils/common';
import { useRegions, useASM, useUSM } from 'api/reportApi';

export const ChartFilterSearchForm = ({ onSubmit, initialValues = {} }) => {
  const [form] = Form.useForm();
  const [region, setRegion] = useState(() => initialValues.region || null);
  const [asm, setAsm] = useState(() => initialValues.asm || null);
  const { data: regions = [], isLoading: isLoadingRegions } = useRegions();
  const { data: asms = [], isLoading: isLoadingAsms } = useASM({
    params: {
      region,
    },
    options: {
      enabled: !_.isNil(region),
    },
  });
  const { data: usms = [], isLoading: isLoadingUsms } = useUSM({
    params: {
      region,
      asm,
    },
    options: {
      enabled: !_.isNil(region) && !_.isNil(asm),
    },
  });

  const handleChangeRegion = (value) => {
    setRegion(value);
    form.setFieldsValue({
      asm: null,
      usm: null,
    });
  };

  const handleChangeAsm = (value) => {
    setAsm(value);
    form.setFieldsValue({
      usm: null,
    });
  };

  const handleSubmit = (values) => {
    onSubmit(
      normalizeSubmitSearchData({
        ...initialValues,
        ...values,
      }),
    );
  };

  return (
    <FilterForm
      onFinish={handleSubmit}
      initialValues={normalizeSearchData(initialValues)}
      form={form}
    >
      <StyledSpace size={16} style={{ justifyContent: 'flex-start' }}>
        <StyledFormItem name="region">
          <StyledSelect
            placeholder="Chọn Region"
            $customType="filter"
            loading={isLoadingRegions}
            allowClear
            onChange={handleChangeRegion}
          >
            {regions.length > 0 &&
              regions.map(({ code, name }) => (
                <Select.Option key={code} value={code}>
                  {name}
                </Select.Option>
              ))}
          </StyledSelect>
        </StyledFormItem>

        <StyledFormItem name="asm">
          <StyledSelect
            placeholder="Chọn ASM"
            $customType="filter"
            loading={isLoadingAsms}
            allowClear
            onChange={handleChangeAsm}
          >
            {asms.length > 0 &&
              asms.map(({ asm }) => (
                <Select.Option key={asm} value={asm}>
                  {asm}
                </Select.Option>
              ))}
          </StyledSelect>
        </StyledFormItem>

        <StyledFormItem name="usm">
          <StyledSelect
            placeholder="Chọn USM"
            $customType="filter"
            loading={isLoadingUsms}
            allowClear
          >
            {usms.length > 0 &&
              usms.map(({ usm, province_name }) => (
                <Select.Option key={`${province_name}_${usm}`} value={usm}>
                  {usm}
                </Select.Option>
              ))}
          </StyledSelect>
        </StyledFormItem>
      </StyledSpace>
    </FilterForm>
  );
};
