import { useQuery } from 'react-query';
import axiosClient from './axiosClient';

export const reportApi = {
  getAllRegions(data) {
    return axiosClient.post('/statistics/region', data);
  },
  getAllASM(data) {
    return axiosClient.post('/statistics/asm', data);
  },
  getAllUSM(data) {
    return axiosClient.post('/statistics/usm', data);
  },
  getDashboardStore(data) {
    return axiosClient.post('/statistics/dashboard_store', data);
  },
  getDashboardShift(data) {
    return axiosClient.post('/statistics/dashboard_shift', data);
  },
  getStoreMap(data) {
    return axiosClient.post('/statistics_performan_mapping/map_store', data);
  },
  getTrackingProgressTableData(data) {
    return axiosClient.post('/statistics/data_report', data);
  },
};

export const useRegions = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['regions', 'list', params],
    queryFn: () => reportApi.getAllRegions(params),
    ...options,
  });
};

export const useASM = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['asm', 'list', params],
    queryFn: () => reportApi.getAllASM(params),
    ...options,
  });
};

export const useUSM = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['usm', 'list', params],
    queryFn: () => reportApi.getAllUSM(params),
    ...options,
  });
};

export const useGetDashboardStore = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard_store', params],
    queryFn: () => reportApi.getDashboardStore(params),
    ...options,
  });
};

export const useDashboardShift = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard_shift', params],
    queryFn: () => reportApi.getDashboardShift(params),
    ...options,
  });
};

export const useStoreMap = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['store_map', params],
    queryFn: () => reportApi.getStoreMap(params),
    ...options,
  });
};

export const useTrackingProgressTableData = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['trackingProgressTableData', params],
    queryFn: () => reportApi.getTrackingProgressTableData(params),
    ...options,
  });
};
