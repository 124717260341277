import { Row, Col } from 'antd';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { ChartFilterSearchForm } from './components/ChartFilterSearchForm';
import { StoreChart } from './components/StoreChart';
import { ShowChart } from './components/ShowChart';
import { TrackingProgressTable } from './components/TrackingProgressTable';
import { StyledContentWrapper, StyledCardWrapper } from 'styles/overrides';

export default function TrackingProgressDashboard() {
  const [search, setSearch] = useCustomSearchParams();

  return (
    <>
      <ChartFilterSearchForm
        onSubmit={(values) => setSearch(values)}
        initialValues={search}
      />
      <StyledContentWrapper>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <StyledCardWrapper>
              <StoreChart search={search} />
            </StyledCardWrapper>
          </Col>

          <Col span={12}>
            <StyledCardWrapper>
              <ShowChart search={search} />
            </StyledCardWrapper>
          </Col>

          <Col span={16} offset={4}>
            <TrackingProgressTable />
          </Col>
        </Row>
      </StyledContentWrapper>
    </>
  );
}
