import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import _ from 'lodash';
import { useStoreMap } from 'api/reportApi';
import { Spin } from 'antd';
import { useMemo } from 'react';
import { DONE_COLOR, NOT_DONE_COLOR, COLOR_WARNING } from 'constants/colors';
import { StyledSpace, StyledTypeStoreMap } from 'styles/overrides';

const DEFAULT_CENTER = {
  lng: 106.6952276,
  lat: 10.754792,
};

const getColor = (type) => {
  switch (type) {
    case 'good':
      return DONE_COLOR;
    case 'medium':
      return COLOR_WARNING;
    case 'weak':
      return NOT_DONE_COLOR;
    default:
      return '#000';
  }
};

const TYPES = [
  { name: 'Tốt', bgColor: DONE_COLOR },
  { name: 'Trung bình', bgColor: COLOR_WARNING },
  { name: 'Yếu', bgColor: NOT_DONE_COLOR },
];

export const StoreMap = ({ search }) => {
  const { data = [], isLoading } = useStoreMap({
    params: search,
    options: {
      select(response) {
        return _.reduce(
          response,
          (result, current) => {
            if (current.vido && current.kinhdo) {
              result.push(current);
            }

            return result;
          },
          [],
        );
      },
    },
  });

  const center = useMemo(() => {
    return {
      lat: +data?.[0]?.vido || DEFAULT_CENTER.lat,
      lng: +data?.[0]?.kinhdo || DEFAULT_CENTER.lng,
    };
  }, [data]);

  return (
    <>
      <StyledSpace style={{ justifyContent: 'center', marginBottom: 32 }}>
        {TYPES.map((item) => (
          <StyledTypeStoreMap style={{ backgroundColor: item.bgColor }}>
            {item.name}
          </StyledTypeStoreMap>
        ))}
      </StyledSpace>
      <Spin spinning={isLoading}>
        <LoadScript googleMapsApiKey="AIzaSyAZlCqi_5ackb2BX65q32Wk90R-OyY8-As">
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: 600 }}
            center={center}
            zoom={12}
          >
            {data.length > 0 &&
              data.map((item) => (
                <Marker
                  position={{
                    lat: +item.vido,
                    lng: +item.kinhdo,
                  }}
                  icon={{
                    path: 'M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z',
                    fillColor: getColor(item.evaluate),
                    fillOpacity: 1,
                    scale: 2,
                    strokeColor: getColor(item.evaluate),
                    strokeWeight: 2,
                  }}
                  key={item.id}
                />
              ))}
          </GoogleMap>
        </LoadScript>
      </Spin>
    </>
  );
};
