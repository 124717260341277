import { Column } from '@ant-design/plots';
import { useDashboardShift } from 'api/reportApi';
import { DONE_COLOR, NOT_DONE_COLOR } from 'constants/colors';

export const ShowChart = ({ search }) => {
  const { data = [], isLoading } = useDashboardShift({
    params: search,
    options: {
      select(response) {
        if (response) {
          return [
            {
              type: 'Đã thực hiện',
              value: response.done,
              total: response.total,
            },
            {
              type: 'Chưa thực hiện',
              value: response.not_done,
              total: response.total,
            },
          ];
        }

        return [];
      },
    },
  });

  const config = {
    data,
    xField: 'type',
    yField: 'value',
    columnWidthRatio: 0.2,
    color: ({ type }) => {
      if (type === 'Đã thực hiện') {
        return DONE_COLOR;
      }

      return NOT_DONE_COLOR;
    },
    label: {
      content: ({ value, total }) => `${((value / total) * 100).toFixed(2)}%`,
    },
  };

  if (isLoading) {
    return null;
  }

  return <Column {...config} />;
};
